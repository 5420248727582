import type { AxiosInstance } from 'axios';
import type {
  LogoutResponse, OAuthRedirectResponse, OAuthResultRequest, OAuthResultResponse,
} from '@/types';

export const authModule = (axios: AxiosInstance) => ({
  getLogoutLink() {
    return axios.get<LogoutResponse>('/v1/auth/logout-link');
  },
  oauthRedirect(fromUrl: string) {
    return axios.get<OAuthRedirectResponse>('/v1/auth/redirect-to-oauth-provider', {
      params: {
        from_url: fromUrl,
      },
    });
  },
  oauthResult(payload: OAuthResultRequest) {
    return axios.post<OAuthResultResponse>('/v1/auth/oauth-result', payload);
  },
});
