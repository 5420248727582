import { useAuthStore } from '@/store/auth';
import { RouteName } from '@/enums';
import type { NavigationGuardWithThis } from 'vue-router';

export const oauthResultHandler: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  const authStore = useAuthStore();

  try {
    const {
      code, session, state, from_url: fromUrl,
    } = to.query as Record<string, string>;

    await authStore.oauthResult({
      code,
      session,
      state,
      fromUrl,
    });
  } catch {
    next({ name: RouteName.ERROR_500 });
  }
};
