import type { AxiosInstance } from 'axios';
import type { EventsData } from './types';

export const eventsModule = (axios: AxiosInstance) => ({
  loadEvents() {
    return axios.get<EventsData[]>('/v1/profile/events');
  },
});

export * as EventsModule from './types';
