import colors from '@modules/themeColors.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { CheckboxProps } from 'naive-ui';

export const lightCheckboxTheme: NonNullable<CheckboxProps['themeOverrides']> = {
  colorChecked: colors.primaryBase,
  borderChecked: `1px solid ${colors.primaryBase}`,
  borderFocus: `1px solid ${colors.primaryBase}`,
  boxShadowFocus: `0 0 0 2px ${colors.primaryBg}`,
  borderRadius: borderRadius.sm,
};
