import { RouteName, LayoutName } from '@/enums';
import type { RouteRecordRaw } from 'vue-router';

const _403 = () => import('@/views/errors/Error403.vue');
const _404 = () => import('@/views/errors/Error404.vue');
const _500 = () => import('@/views/errors/Error500.vue');

const errorsRoutes: RouteRecordRaw[] = [
  {
    path: '/403',
    name: RouteName.ERROR_403,
    component: _403,
    meta: {
      layout: LayoutName.ERROR,
      title: 'Доступ на эту страницу запрещен',
    },
  },
  {
    path: '/404',
    name: RouteName.ERROR_404,
    component: _404,
    meta: {
      layout: LayoutName.ERROR,
      title: 'Прекрасная страница, каких больше не делают',
    },
  },
  {
    path: '/500',
    name: RouteName.ERROR_500,
    component: _500,
    meta: {
      layout: LayoutName.ERROR,
      title: 'Внутренняя ошибка сервера',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => ({ name: RouteName.ERROR_404 }),
  },
];

export default errorsRoutes;
