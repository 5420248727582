import colors from '@modules/themeColors.module.scss';
import coreColors from '@modules/coreColors.module.scss';
import paddings from '@modules/paddings.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { TooltipProps } from 'naive-ui';

export const lightTooltipTheme: NonNullable<TooltipProps['themeOverrides']> = {
  borderRadius: borderRadius.base,
  boxShadow: '0px 10px 15px -3px rgba(16, 24, 40, 0.10), 0px 0px 20px -4px rgba(16, 24, 40, 0.10)',
  color: colors.text,
  padding: paddings.xs,
  textColor: coreColors.colorTextLightSolid,
};
