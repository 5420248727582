import breakpoints from '@modules/breakpoints.module.scss';
import { gtm } from './gtm';
import { adaptive } from '@/plugins/adaptive';
import { omnideskWidget } from '@/services/widgets';
import type { App } from 'vue';
import type { PluginAdaptiveOptions } from '@/plugins/adaptive';

export function registerPlugins(app: App) {
  app.use(gtm);

  const adaptiveOptions: PluginAdaptiveOptions = {
    breakpoints: {
      xxs: parseInt(breakpoints.xxs),
      xs: parseInt(breakpoints.xs),
      sm: parseInt(breakpoints.sm),
      md: parseInt(breakpoints.md),
      lg: parseInt(breakpoints.lg),
      xl: parseInt(breakpoints.xl),
      xxl: parseInt(breakpoints.xxl),
    },
  };
  app.use(adaptive, adaptiveOptions);

  if (process.env.NODE_ENV === 'production') omnideskWidget.create();
}
