import type { AxiosInstance } from 'axios';
import type { Schedule2035Opportunity } from './types';

export const schedule2035Module = (axios: AxiosInstance) => ({
  loadSchedule2035List() {
    return axios.get<Schedule2035Opportunity>('/v1/schedule/opportunity/2035');
  },

  enrollGroup(campaignUuid: string, opportunityUuid: string, groupUuid: string) {
    return axios.post('/v1/schedule/group/enroll', {
      campaignUuid,
      opportunityUuid,
      groupUuid,
    });
  },

  createByGroceryBasket(groceryBasketUuid: string, campaignUuid: string) {
    return axios.post('/v1/profile/contract/create-by-grocery-basket', {
      groceryBasketUuid,
      campaignUuid,
    });
  },
});

export * as Schedule2035Module from './types';
