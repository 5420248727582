import { reactive } from 'vue';
import debounce from 'lodash/debounce';
import type { App } from 'vue';

/*
Plugin требует opts.breakpoints объект с ключами брейкпойнтов и значениями в числах
opts.throttle - задержка вызовов между вычислениями ресайза
Пример:
  opts = {
    breakpoints: {
        tiny: 640,
        small: 764,
        medium: 1024,
        wide: 1200,
    },
    throttle: 66,
  };
  Тогда $adaptive.is.tiny будет true при window.innerWidth < 640
*/

export type PluginAdaptiveOptions = {
  breakpoints: Record<string, number>;
};

export const adaptive = {
  install(app: App, opts: PluginAdaptiveOptions) {
    const { breakpoints } = opts;
    const bps = Object.entries(breakpoints).sort((entry1, entry2) => {
      return entry1[1] - entry2[1];
    });

    const getSectionByWindowSize = (windowSize: number) => {
      let currentBp = 'big';
      for (let i = 0; i < bps.length; i++) {
        const [key, value] = bps[i];
        if (windowSize < value) {
          currentBp = key;
          break;
        }
      }
      return currentBp;
    };

    const getBreakpointsAboveSize = (size: number) => {
      return bps.filter((bp) => bp[1] >= size).map((bp) => bp[0]);
    };

    const state = reactive({
      currentSection: getSectionByWindowSize(window.innerWidth),
      currentSizeBelowBreakpoints: getBreakpointsAboveSize(window.innerWidth),
    });

    const callback = debounce(() => {
      state.currentSection = getSectionByWindowSize(window.innerWidth);
      state.currentSizeBelowBreakpoints = getBreakpointsAboveSize(window.innerWidth);
    });
    window.addEventListener('resize', callback);
    window.addEventListener('orientationchange', callback);

    app.config.globalProperties.$adaptive = state;
    app.provide('adaptive', state);
  },
};
