import colors from '@modules/themeColors.module.scss';
import sizes from '@modules/sizes.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { PaginationProps } from 'naive-ui';

export const lightPaginationTheme: NonNullable<PaginationProps['themeOverrides']> = {
  buttonColor: 'transparent',
  buttonBorder: 'transparent',
  buttonColorHover: colors.bgTextHover,
  buttonBorderHover: colors.bgTextHover,
  buttonColorPressed: 'transparent',
  buttonBorderPressed: `1px solid ${colors.text}`,
  itemBorderDisabled: '1px solid transparent',
  itemTextColorDisabled: colors.textDisabled,
  itemTextColor: colors.text,
  itemTextColorHover: colors.text,
  itemTextColorPressed: colors.text,
  itemTextColorActive: colors.text,
  itemColor: 'transparent',
  itemColorHover: colors.bgTextHover,
  itemColorDisabled: 'transparent',
  itemBorderPressed: `1px solid ${colors.text}`,
  itemBorderActive: `1px solid ${colors.text}`,
  itemPaddingMedium: '6px',
  itemBorderRadius: borderRadius.base,
  itemSizeMedium: sizes.xl,
};
