import type { GetCrmUuid, GetUserRole, YandexMetrikaManagerType } from '../types';

const createMenuModule = (ymm: YandexMetrikaManagerType, getCrmUuid: GetCrmUuid, getUserRole: GetUserRole) => ({
  menuClick(code: string) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_click: {
            categories: {
              [code]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createMenuModule;
