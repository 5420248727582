import { isAxiosError, HttpStatusCode } from 'axios';
import useConfigStore from '@/store/config';
import useUserStore from '@/store/user';
import { ymm } from '@/utils';
import { RouteName } from '@/enums';
import type { NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';

const exceptionsRoutes: RouteName[] = [RouteName.OAUTH_RESULT];

const loadConfig = async (to: RouteLocationNormalized) => {
  if (exceptionsRoutes.includes(to.name as RouteName)) return;

  const configStore = useConfigStore();

  if (configStore.CONFIG) return;

  try {
    await configStore.getConfig();
  } catch (e) {
    if (!isAxiosError(e)) throw e;

    const resStatus = e.response?.status;

    if (resStatus == null) throw e;

    if (resStatus !== HttpStatusCode.Unauthorized) throw e;
  }
};

const loadUser = async (to: RouteLocationNormalized) => {
  if (exceptionsRoutes.includes(to.name as RouteName)) return;

  const userStore = useUserStore();

  if (userStore.user) return;

  try {
    await userStore.getUser();
    ymm.profile.sendProfileInfo();
  } catch (e) {
    if (!isAxiosError(e)) throw e;

    const resStatus = e.response?.status;

    if (resStatus == null) throw e;

    if (resStatus !== HttpStatusCode.Unauthorized) throw e;
  }
};

export const loadInitialData: NavigationGuardWithThis<undefined> = async (to) => {
  const [userPromise, configPromise] = await Promise.allSettled([
    loadUser(to),
    loadConfig(to),
  ]);

  const isSomeRejected = userPromise.status === 'rejected' || configPromise.status === 'rejected';

  if (isSomeRejected) return { name: RouteName.ERROR_500 };

  const userStore = useUserStore();

  return !!userStore.user;
};
