import { RouteName } from '@/enums';
import useUserStore from '@/store/user';
import type { NavigationGuardWithThis } from 'vue-router';

export const redirectByRole: NavigationGuardWithThis<undefined> = (to) => {
  const userStore = useUserStore();

  if (to.name === RouteName.ADMIN) {
    if (!userStore.isAdminRole) return { name: RouteName.ERROR_403 };
  } else if (userStore.isAdminRole) return { name: RouteName.ADMIN };

  if (to.name === RouteName.HOME) {
    if (userStore.isParentRole) return { name: RouteName.PROGRESS };
    if (userStore.isTeacherRole) return { name: RouteName.PROFILE };
    return { name: RouteName.CONTRACTS_AND_PAYMENTS };
  }

  return true;
};
