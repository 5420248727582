import colors from '@modules/themeColors.module.scss';
import fonts from '@modules/fonts.module.scss';
import type { UploadProps } from 'naive-ui';

export const lightUploadTheme: NonNullable<UploadProps['themeOverrides']> = {
  itemIconColor: colors.textDescription,
  itemTextColor: colors.primaryBase,
  borderRadius: '0',
  itemColorHover: colors.controlItemBgHover,
  itemColorHoverError: colors.controlItemBgHover,
  itemTextColorSuccess: colors.primaryBase,
  fontSize: fonts.base,
  itemTextColorError: colors.errorBase,
  lineHeight: '22px',
};
