import type { AxiosInstance } from 'axios';
import type { ProgressStudent, ProgressData } from './types';

export const progressModule = (axios: AxiosInstance) => ({
  loadProgressGroups() {
    return axios.get<ProgressStudent[]>('/v1/parent/children/progress/groups');
  },

  loadProgressData(studentUuid: string, groupId: number) {
    return axios.get<ProgressData>('/v1/parent/children/progress/list', {
      params: {
        studentUuid,
        groupId,
      },
    });
  },
});

export * as ProgressModule from './types';
