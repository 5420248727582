import type { AxiosInstance } from 'axios';
import type { StudentScheduleBackend, ParentScheduleBackend } from './types';

export const scheduleModule = (axios: AxiosInstance) => ({
  loadParentScheduleList(monthDate: string) {
    return axios.get<ParentScheduleBackend[]>('/v1/parent/children/schedule/list', {
      params: {
        month_date: monthDate,
      },
    });
  },

  loadStudentScheduleList(monthDate: string) {
    return axios.get<StudentScheduleBackend>('/v1/student/schedule/list', {
      params: {
        month_date: monthDate,
      },
    });
  },
});

export * as ScheduleModule from './types';
