import type { GetUserRole, GetCrmUuid, YandexMetrikaManagerType } from '../types';

const createScheduleModule = (ymm: YandexMetrikaManagerType, getCrmUuid: GetCrmUuid, getUserRole: GetUserRole) => ({
  click(activityType?: string) {
    if (!activityType) return;

    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_click: {
            schedule_type_activity: {
              [activityType]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },

  show() {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_show: {
            schedule_type_activity: {
              show_type_activity: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createScheduleModule;
