import colors from '@modules/themeColors.module.scss';
import coreColors from '@modules/coreColors.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import paddings from '@modules/paddings.module.scss';
import fonts from '@modules/fonts.module.scss';
import heights from '@modules/heights.module.scss';
import type { TagProps } from 'naive-ui';

export const lightTagTheme: NonNullable<TagProps['themeOverrides']> = {
  borderRadius: borderRadius.sm,
  borderInfo: `1px solid ${coreColors.brandBlue3}`,
  colorInfo: coreColors.brandBlue1,
  padding: `0 ${paddings.xs}`,
  fontWeightStrong: '400',

  fontSizeMedium: fonts.base,
  heightMedium: heights.sm,

  fontSizeLarge: fonts.lg,
  heightLarge: heights.base,

  fontSizeSmall: fonts.sm,
  heightSmall: '22px',

  // primary
  textColorPrimary: coreColors.colorTextLightSolid,
  colorPrimary: colors.brandOrange,

  // warning
  textColorWarning: coreColors.colorTextLightSolid,
  colorWarning: coreColors.colorWarning,

  // error
  textColorError: coreColors.colorTextLightSolid,
  colorError: coreColors.brandRed6,
};
