import { RouterView } from 'vue-router';
import { LayoutName, RouteName } from '@/enums';
import { oauthResultHandler } from '@/router/guards';
import type { RouteRecordRaw } from 'vue-router';

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/auth/oauth-result',
    name: RouteName.OAUTH_RESULT,
    component: RouterView,
    beforeEnter: [oauthResultHandler],
    meta: {
      layout: LayoutName.EMPTY,
    },
  },
];

export default authRoutes;
