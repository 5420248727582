import colors from '@modules/themeColors.module.scss';
import coreColors from '@modules/coreColors.module.scss';
import fonts from '@modules/fonts.module.scss';
import type { StepsProps } from 'naive-ui';

export const lightStepsTheme: NonNullable<StepsProps['themeOverrides']> = {
  stepHeaderFontWeight: fonts.weightNormal,
  stepHeaderFontSizeMedium: fonts.base,

  // process
  indicatorColorProcess: colors.brandOrange,
  indicatorBorderColorProcess: colors.brandOrange,
  headerTextColorProcess: colors.text,
  splitorColorProcess: colors.bgTextHover,

  // wait
  indicatorTextColorWait: colors.textDescription,
  indicatorBorderColorWait: colors.bgTextHover,
  indicatorColorWait: colors.bgTextHover,
  splitorColorWait: colors.bgTextHover,

  // finish
  indicatorColorFinish: coreColors.brandOrange2,
  indicatorBorderColorFinish: coreColors.brandOrange2,
  indicatorTextColorFinish: colors.brandOrange,
  headerTextColorFinish: colors.text,
  splitorColorFinish: colors.brandOrange,

  // error
  indicatorColorError: colors.errorBase,
  indicatorBorderColorError: colors.errorBase,
  headerTextColorError: colors.errorBase,
  splitorColorError: colors.bgTextHover,
};
