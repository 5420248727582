// eslint-disable-next-line no-restricted-imports
import { NAlert, useMessage as naiveUseMessage } from 'naive-ui';
import { h } from 'vue';
import type { MessageApi, MessageRenderMessage, AlertProps } from 'naive-ui';

type ContentType = Parameters<MessageApi['error']>[0];
type Options = Parameters<MessageApi['error']>[1] & {
  description?: string;
};
type MessageRenderMessageExtended = (options: Options) => MessageRenderMessage;

const getRenderFunc: MessageRenderMessageExtended = (options: Options) => {
  return (props) => h(
    NAlert,
    {
      closable: props.closable,
      onClose: props.onClose,
      type: props.type as AlertProps['type'],
      title: props.content as string,
    },
    {
      default: () => options.description,
    },
  );
};

const renderMessage: Partial<Record<keyof MessageApi, MessageRenderMessageExtended>> = {
  error: (options) => getRenderFunc(options),
  success: (options) => getRenderFunc(options),
};

type MessageApiItem = (content: ContentType, options: Options) => void;
type MessageApiExtended = Record<keyof MessageApi, MessageApiItem>;

export function useMessage() {
  const message = naiveUseMessage();

  return Object.entries(message).reduce<MessageApiExtended>((acc, [k, v]) => {
    const key = k as keyof MessageApi;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = v as any;

    acc[key] = (content, options) => {
      const extendedFunc = renderMessage[key];
      if (extendedFunc && options.description) {
        value(content, {
          render: extendedFunc(options),
          ...options,
        });
      } else {
        value(content, options);
      }
    };

    return acc;
  }, {} as MessageApiExtended);
}
