export enum UserRole {
  STUDENT = 'student',
  TEACHER = 'teacher',
  SUPER_ADMINISTRATOR = 'super_administrator',
  ADMINISTRATOR = 'administrator',
  TER_ADMIN = 'ter_admin',
  USER_MANAGER = 'user_manager',
  PARENT = 'parent',
  CRM = 'crm',
  PAY_APP = 'pay_app',
  TEAM_LEADER = 'team_leader',
  PARTNER = 'partner',
  INNOPOLIS_TEACHER = 'innopolis_teacher',
  INNOPOLIS_STUDENT = 'innopolis_student',
  INNOPOLIS_VERIFICATOR = 'innopolis_verificator',
  RTK_USER = 'rtk_user',
}
