import type { AxiosInstance } from 'axios';
import type { ContractsData, PaymentLink } from './types';

export const contractsAndPaymentsModule = (axios: AxiosInstance) => ({
  loadContractList() {
    return axios.get<ContractsData[]>('/v1/profile/contract/list');
  },

  loadPaymentLink(contractUuid: string, paymentUuid: string) {
    return axios.get<PaymentLink>(`/v1/profile/contract/${contractUuid}/payment/${paymentUuid}/bill-link`);
  },
});

export * as ContractsAndPaymentsModule from './types';
