import authRoutes from './auth';
import errorsRoutes from './errors';
import { LayoutName, RouteName } from '@/enums';
import type { RouteRecordRaw } from 'vue-router';

const _home = () => import('@/views/Home.vue');
const _admin = () => import('@/views/AdminPanel.vue');
const _logout = () => import('@/views/LogoutPage.vue');

const _catalog = () => import('@/views/Catalog.vue');
const _contractAndPayments = () => import('@/views/ContractsAndPayments.vue');
const _contractAndPaymentsDetail = () => import('@/views/ContractsAndPaymentsDetail.vue');
const _events = () => import('@/views/Events.vue');
const _gradeBook = () => import('@/views/GradeBook.vue');
const _profile = () => import('@/views/Profile.vue');
const _progress = () => import('@/views/Progress.vue');
const _schedule = () => import('@/views/Schedule.vue');
const _schedule2035 = () => import('@/views/Schedule2035.vue');
const _scheduleChange = () => import('@/views/ScheduleChange.vue');
const _taxDeduction = () => import('@/views/TaxDeduction.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteName.HOME,
    component: _home,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/logout',
    name: RouteName.LOGOUT,
    component: _logout,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/catalog',
    name: RouteName.CATALOG,
    component: _catalog,
    meta: {
      layout: LayoutName.HEADER_AND_FOOTER_LAYOUT,
    },
  },
  {
    path: '/contracts-and-payments',
    name: RouteName.CONTRACTS_AND_PAYMENTS,
    component: _contractAndPayments,
    meta: {
      layout: LayoutName.DEFAULT,
      gtmLabel: 'documents_and_payments',
    },
  },
  {
    path: '/contracts-and-payments/:id',
    name: RouteName.CONTRACTS_AND_PAYMENTS_DETAIL,
    component: _contractAndPaymentsDetail,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/events',
    name: RouteName.EVENTS,
    component: _events,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/profile',
    name: RouteName.PROFILE,
    component: _profile,
    meta: {
      layout: LayoutName.DEFAULT,
      gtmLabel: 'profile',
    },
  },
  {
    path: '/progress',
    name: RouteName.PROGRESS,
    component: _progress,
    meta: {
      layout: LayoutName.DEFAULT,
      gtmLabel: 'progress',
    },
  },
  {
    path: '/schedule',
    name: RouteName.SCHEDULE,
    component: _schedule,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/2035',
    name: RouteName.SCHEDULE_2035,
    component: _schedule2035,
    meta: {
      layout: LayoutName.DEFAULT,
      gtmLabel: '2035',
    },
  },
  {
    path: '/change-schedule',
    name: RouteName.SCHEDULE_CHANGE,
    component: _scheduleChange,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/tax-deduction',
    name: RouteName.TAX_DEDUCTION,
    component: _taxDeduction,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/grade-book/',
    name: RouteName.GRADE_BOOK,
    component: _gradeBook,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  {
    path: '/adm-panel/',
    name: RouteName.ADMIN,
    component: _admin,
    meta: {
      layout: LayoutName.DEFAULT,
    },
  },
  ...authRoutes,
  ...errorsRoutes,
];

export default routes;
