import colors from '@modules/themeColors.module.scss';
import paddings from '@modules/paddings.module.scss';
import margins from '@modules/margins.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { AlertProps } from 'naive-ui';

export const lightAlertTheme: NonNullable<AlertProps['themeOverrides']> = {
  fontSize: '14px',
  lineHeight: '22px',
  titleFontWeight: '500',
  iconSize: '20px',
  iconMargin: `${margins.md} ${margins.sm} 0 ${margins.lg}`,
  closeIconSize: '14px',
  closeMargin: `${margins.md} ${margins.lg} 0 0`,
  padding: `${paddings.md} ${paddings.lg}`,
  borderRadius: borderRadius.lg,

  // error
  iconColorError: colors.errorBase,
  borderError: `1px solid ${colors.errorBorder}`,
  colorError: colors.errorBg,
  titleTextColorError: colors.text,
  contentTextColorError: colors.text,

  // success
  iconColorSuccess: colors.successBase,
  borderSuccess: `1px solid ${colors.successBorder}`,
  colorSuccess: colors.successBg,
  titleTextColorSuccess: colors.text,
  contentTextColorSuccess: colors.text,

  // warning
  iconColorWarning: colors.warningBase,
  borderWarning: `1px solid ${colors.warningBorder}`,
  colorWarning: colors.warningBg,
  titleTextColorWarning: colors.text,
  contentTextColorWarning: colors.text,
};
