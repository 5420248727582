import fonts from '@modules/fonts.module.scss';
import {
  lightAlertTheme,
  lightBreadcrumbTheme,
  lightButtonTheme,
  lightCardTheme,
  lightCheckboxTheme,
  lightCollapseTheme,
  lightDataTableTheme,
  lightDividerTheme,
  lightFormTheme,
  lightInputTheme,
  lightMenuTheme,
  lightModalTheme,
  lightPaginationTheme,
  lightProgressTheme,
  lightRadioTheme,
  lightResultTheme,
  lightSelectTheme,
  lightStepsTheme,
  lightSwitchTheme,
  lightTagTheme,
  lightTooltipTheme,
  lightTypographyTheme,
  lightUploadTheme,
  lightTabsTheme,
} from '@/themes/components';
import type { GlobalThemeOverrides } from 'naive-ui';

export const lightGlobalTheme = {
  common: {
    fontFamily: `${fonts.name}, sans-serif`,
    fontFamilyMono: `${fonts.name}, sans-serif`,
    fontWeight: fonts.weightNormal,
    fontWeightStrong: fonts.weightStrong,
  },
  Alert: lightAlertTheme,
  Breadcrumb: lightBreadcrumbTheme,
  Button: lightButtonTheme,
  Card: lightCardTheme,
  Checkbox: lightCheckboxTheme,
  Collapse: lightCollapseTheme,
  DataTable: lightDataTableTheme,
  Divider: lightDividerTheme,
  Form: lightFormTheme,
  Input: lightInputTheme,
  Menu: lightMenuTheme,
  Modal: lightModalTheme,
  Pagination: lightPaginationTheme,
  Progress: lightProgressTheme,
  Radio: lightRadioTheme,
  Result: lightResultTheme,
  Select: lightSelectTheme,
  Steps: lightStepsTheme,
  Switch: lightSwitchTheme,
  Tag: lightTagTheme,
  Tooltip: lightTooltipTheme,
  Typography: lightTypographyTheme,
  Upload: lightUploadTheme,
  Tabs: lightTabsTheme,
} satisfies GlobalThemeOverrides;
