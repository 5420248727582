import YandexMetrikaManager from '@umax/yandex-metrika-manager';
import createMenuModule from './modules/menu';
import createProfileModule from './modules/profile';
import createDocumentsModule from './modules/documents';
import createProgressModule from './modules/progress';
import createScheduleModule from './modules/schedule';
import createSchedule2035Module from './modules/schedule2035';
import createScheduleChangeModule from './modules/scheduleChange';
import useUserStore from '@/store/user';
import type { YaMetrikaOptions } from '@umax/yandex-metrika-manager';

const ymOptions: YaMetrikaOptions = {
  accurateTrackBounce: true,
  clickmap: true,
  trackHash: true,
  trackLinks: true,
  webvisor: true,
};

const yandexMetrikaManager = new YandexMetrikaManager<37655510 | 93175160>([
  { id: 37655510, enabled: process.env.NODE_ENV === 'production', ymOptions },
  { id: 93175160, enabled: process.env.NODE_ENV === 'production', ymOptions },
]);

const ymm = {
  manager: yandexMetrikaManager,
  menu: createMenuModule(yandexMetrikaManager, getCrmUuid, getUserRole),
  profile: createProfileModule(yandexMetrikaManager, getCrmUuid, getUserRole),
  documents: createDocumentsModule(yandexMetrikaManager, getCrmUuid, getUserRole),
  progress: createProgressModule(yandexMetrikaManager, getCrmUuid, getUserRole),
  schedule: createScheduleModule(yandexMetrikaManager, getCrmUuid, getUserRole),
  schedule2035: createSchedule2035Module(yandexMetrikaManager, getCrmUuid, getUserRole),
  scheduleChange: createScheduleChangeModule(yandexMetrikaManager, getCrmUuid, getUserRole),
};

function getCrmUuid(): string | undefined {
  const userStore = useUserStore();
  return userStore.crmUuid;
}

function getUserRole(): string | undefined {
  const userStore = useUserStore();
  return userStore.role;
}

export default ymm;
export * from './types';
