import type { GetUserRole, GetCrmUuid, YandexMetrikaManagerType } from '../types';

const createProfileModule = (ymm: YandexMetrikaManagerType, getCrmUuid: GetCrmUuid, getUserRole: GetUserRole) => ({
  sendProfileInfo() {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const userId = crmUuid ?? 'unknown';

    ymm.counters['37655510'].setUserID(userId);
    ymm.counters['93175160'].setUserID(userId);

    ymm.counters['37655510'].userParams({ UserID: userId });
    ymm.counters['93175160'].userParams({ UserID: userId });

    const payload = {
      UserID: {
        PROFILE: {
          crm_uuid: {
            [crmUuid]: userRole,
          },
        },
      },
    };

    ymm.counters['37655510'].params(payload);
    ymm.counters['93175160'].params(payload);
  },

  burger() {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_click: {
            profile_burger: {
              burger: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },

  edit(action: string) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_click: {
            profile_edit: {
              [action]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createProfileModule;
