import { createGtm } from '@gtm-support/vue-gtm';
import router from '@/router';

export const gtm = createGtm({
  id: 'GTM-58HF6K5',
  enabled: process.env.NODE_ENV === 'production',
  debug: false,
  loadScript: true,
  vueRouter: router,
});
