import type { AxiosInstance } from 'axios';
import type {
  UserData,
  UserUpdateData,
  GetAvatarFileResponse,
  UserAccount,
  AccountType,
  IamOauthUrlResponse,
  VerificationSendRequestData,
  VerificationSendResponse,
  VerificationVerifyRequestData,
  PasswordChangeRequestData,
} from './types';

export const profileModule = (axios: AxiosInstance) => ({
  loadProfile() {
    return axios.get<UserData>('/v1/profile');
  },

  updateProfile(payload: UserUpdateData) {
    return axios.patch<UserData>('/v1/profile', payload);
  },

  sendAvatarFile(file: File) {
    const extension = file.name.split('.').pop();

    return axios.post<GetAvatarFileResponse>(
      '/v1/storage/avatar',
      file,
      {
        headers: {
          'Content-Type': file.type ?? '',
          'X-File-Extension': extension!.toLowerCase(),
        },
      },
    );
  },

  loadUserAccounts() {
    return axios.get<UserAccount[]>('/v1/profile/account/list');
  },

  getSocialRedirectUrl(accountType: AccountType) {
    return axios.get<IamOauthUrlResponse>(`/v1/profile/account/iam-oauth-url/${accountType}`);
  },

  unlinkAccount(accountId: number) {
    return axios.delete(`v1/profile/account/${accountId}`);
  },

  sendAccountVerification(payload: VerificationSendRequestData, accountType: AccountType | null) {
    if (accountType === 'phone') {
      return axios.post<VerificationSendResponse>(
        'v1/profile/account/verification/send',
        { phone: payload.phone },
      );
    }
    return axios.post<VerificationSendResponse>(
      'v1/profile/account/verification/send',
      { email: payload.email },
    );
  },

  verifyAccountLinking(payload: VerificationVerifyRequestData, accountType: AccountType | null) {
    if (accountType === 'phone') {
      return axios.post<void>(
        'v1/profile/account/verification/verify',
        {
          phone: payload.phone,
          code: payload.code,
        },
      );
    }
    return axios.post<void>(
      'v1/profile/account/verification/verify',
      {
        email: payload.email,
        code: payload.code,
      },
    );
  },

  setPassword(password: string | null) {
    return axios.post<void>('v1/profile/password/set-first', { password });
  },

  changePassword(payload: PasswordChangeRequestData) {
    return axios.patch<void>('v1/profile/password/change', payload);
  },
});

export * as ProfileModule from './types';
