import type { GradeBookBackend, ParentGradeBookListBackend } from '@/types';
import type { AxiosInstance } from 'axios';

export const gradeBookModule = (axios: AxiosInstance) => ({
  loadParentGradeBookList() {
    return axios.get<ParentGradeBookListBackend>('/v1/parent/children/grade-book/list');
  },
  loadStudentGradeBook() {
    return axios.get<GradeBookBackend>('/v1/student/grade-book/list');
  },
});
