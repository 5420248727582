/**
 * @see https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/directives/click-outside/index.ts
 */

// Utilities
import { attachedRoot } from '@/utils/dom';

// Types
import type { DirectiveBinding } from 'vue';

interface ClickOutsideBindingArgs {
  handler: (e: MouseEvent) => void;
  closeConditional?: (e: Event) => boolean;
  include?: () => HTMLElement[];
}

interface ClickOutsideDirectiveBinding extends DirectiveBinding {
  value: ((e: MouseEvent) => void) | ClickOutsideBindingArgs;
}

function defaultConditional() {
  return true;
}

function checkIsActive(e: MouseEvent, binding: ClickOutsideDirectiveBinding): boolean | void {
  const isActive = (typeof binding.value === 'object' && binding.value.closeConditional) || defaultConditional;

  return isActive(e);
}

function checkEvent(e: MouseEvent, el: HTMLElement, binding: ClickOutsideDirectiveBinding): boolean {
  if (!e || checkIsActive(e, binding) === false) return false;

  const root = attachedRoot(el);
  if (
    typeof ShadowRoot !== 'undefined'
    && root instanceof ShadowRoot
    && root.host === e.target
  ) return false;

  const elements = ((typeof binding.value === 'object' && binding.value.include) || (() => []))();
  elements.push(el);

  return !elements.some((el) => el?.contains(e.target as Node));
}

function directive(e: MouseEvent, el: HTMLElement, binding: ClickOutsideDirectiveBinding) {
  const handler = typeof binding.value === 'function' ? binding.value : binding.value.handler;

  // eslint-disable-next-line no-unused-expressions
  el._clickOutside!.lastMousedownWasOutside && checkEvent(e, el, binding) && setTimeout(() => {
    // eslint-disable-next-line no-unused-expressions
    checkIsActive(e, binding) && handler && handler(e);
  }, 0);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function handleShadow(el: HTMLElement, callback: Function): void {
  const root = attachedRoot(el);

  callback(document);

  if (typeof ShadowRoot !== 'undefined' && root instanceof ShadowRoot) {
    callback(root);
  }
}

export const ClickOutside = {
  mounted(el: HTMLElement, binding: ClickOutsideDirectiveBinding) {
    const onClick = (e: Event) => directive(e as MouseEvent, el, binding);
    const onMousedown = (e: Event) => {
      el._clickOutside!.lastMousedownWasOutside = checkEvent(e as MouseEvent, el, binding);
    };

    handleShadow(el, (app: HTMLElement) => {
      app.addEventListener('click', onClick, true);
      app.addEventListener('mousedown', onMousedown, true);
    });

    if (!el._clickOutside) {
      el._clickOutside = {
        lastMousedownWasOutside: false,
      };
    }

    el._clickOutside[binding.instance!.$.uid] = {
      onClick,
      onMousedown,
    };
  },

  unmounted(el: HTMLElement, binding: ClickOutsideDirectiveBinding) {
    if (!el._clickOutside) return;

    handleShadow(el, (app: HTMLElement) => {
      if (!app || !el._clickOutside?.[binding.instance!.$.uid]) return;

      const { onClick, onMousedown } = el._clickOutside[binding.instance!.$.uid]!;

      app.removeEventListener('click', onClick, true);
      app.removeEventListener('mousedown', onMousedown, true);
    });

    delete el._clickOutside[binding.instance!.$.uid];
  },
};

export default ClickOutside;
