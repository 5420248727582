import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { loadInitialData, redirectByRole } from '@/router/guards';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(loadInitialData);
router.beforeEach(redirectByRole);
export default router;
