import colors from '@modules/themeColors.module.scss';
import coreColors from '@modules/coreColors.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { NRadio } from 'naive-ui';
import type { VueComponentType } from '@/types/utils';

type RadioProps = VueComponentType<typeof NRadio>;

export const lightRadioTheme: NonNullable<RadioProps['themeOverrides']> = {
  dotColorActive: colors.primaryBase,
  boxShadowActive: `inset 0 0 0 1px ${colors.primaryBase}`,
  boxShadowHover: `inset 0 0 0 1px ${colors.primaryBase}`,
  boxShadowFocus: `inset 0 0 0 1px ${colors.primaryBase}, 0 0 0 2px ${colors.primaryBg}`,

  radioSizeLarge: '24px',

  labelFontWeight: '400',
  labelLineHeight: '24px',
  fontSizeLarge: '16px',

  // Radio Button
  buttonBorderRadius: borderRadius.base,
  buttonBorderColor: colors.border,
  buttonBorderColorHover: colors.brandOrange,
  buttonBorderColorActive: colors.brandOrange,
  buttonColor: colors.bgContainer,
  buttonColorActive: colors.brandOrange,
  buttonTextColor: colors.text,
  buttonTextColorHover: colors.brandOrange,
  buttonTextColorActive: coreColors.colorTextLightSolid,
  buttonBoxShadow: 'none',
  buttonBoxShadowFocus: 'none',
  buttonBoxShadowHover: 'none',
};
