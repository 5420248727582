import type { GetUserRole, GetCrmUuid, YandexMetrikaManagerType, YandexCategory } from '../types';

const createSchedule2035Module = (ymm: YandexMetrikaManagerType, getCrmUuid: GetCrmUuid, getUserRole: GetUserRole) => ({
  clickOrShow(label: string, category: YandexCategory) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          [`personal_account_${category}`]: {
            2035: {
              [label]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createSchedule2035Module;
