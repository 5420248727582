import { differenceInMilliseconds } from 'date-fns';

// Если у пользователя есть разница между временем сервера
// то проставляем эту разницу в миллисекундах
let userTimeOffset: number | null = null;

export function setTimeOffset(serverTimestamp: string) {
  if (userTimeOffset !== null) return;
  userTimeOffset = differenceInMilliseconds(new Date(serverTimestamp), new Date());
}

export function transformTimestamp(serverTimestamp: number) {
  return serverTimestamp * 1000 - userTimeOffset!;
}
