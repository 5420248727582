import themeColors from '@modules/themeColors.module.scss';
import type { CollapseProps } from 'naive-ui';

export const lightCollapseTheme: NonNullable<CollapseProps['themeOverrides']> = {
  titleFontSize: '20px',
  titleFontWeight: '600',
  titleTextColor: themeColors.text,
  arrowColor: themeColors.text,
  dividerColor: themeColors.border,
};
