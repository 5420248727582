import colors from '@modules/themeColors.module.scss';
import type { ProgressProps } from 'naive-ui';

export const lightProgressTheme: NonNullable<ProgressProps['themeOverrides']> = {
  fontSize: '16px',
  fontSizeCircle: '30px',
  fontWeightCircle: '500',
  textColorCircle: colors.text,
  textColorLineOuter: colors.text,
  fillColor: colors.infoBase,
  fillColorInfo: colors.infoBase,
  fillColorSuccess: colors.successBase,
  fillColorWarning: colors.brandOrange,
  fillColorError: colors.errorBase,
};
