import colors from '@modules/themeColors.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import type { MenuProps } from 'naive-ui';

export const lightMenuTheme: NonNullable<MenuProps['themeOverrides']> = {
  itemTextColor: colors.text,
  itemTextColorHover: colors.text,
  itemTextColorActive: colors.text,
  itemTextColorActiveHover: colors.text,
  itemColorHover: colors.bgTextHover,
  itemColorActive: colors.bgTextHover,
  itemColorActiveHover: colors.bgTextHover,
  borderRadius: borderRadius.sm,
};
