import colors from '@modules/themeColors.module.scss';
import fonts from '@modules/fonts.module.scss';
import type { FormProps } from 'naive-ui';

export const lightFormTheme: NonNullable<FormProps['themeOverrides']> = {
  labelFontSizeLeftMedium: fonts.base,
  labelFontSizeTopMedium: fonts.base,
  labelHeightMedium: '22px',

  lineHeight: '1.5',
  labelFontWeight: fonts.base,
  labelPaddingVertical: '0',
  labelPaddingHorizontal: '0',

  labelFontSizeTopLarge: fonts.lg,
  labelFontSizeLeftLarge: fonts.lg,
  labelHeightLarge: fonts.heading3,

  labelTextColor: colors.text,
  feedbackTextColorError: colors.errorBase,
  asteriskColor: colors.errorBase,

  feedbackHeightMedium: '0',
};
