import colors from '@modules/themeColors.module.scss';
import borderRadius from '@modules/borderRadius.module.scss';
import fonts from '@modules/fonts.module.scss';
import heights from '@modules/heights.module.scss';
import paddings from '@modules/paddings.module.scss';
import type { SelectProps } from 'naive-ui';

export const lightSelectTheme: NonNullable<SelectProps['themeOverrides']> = {
  peers: {
    InternalSelectMenu: {
      borderRadius: borderRadius.lg,
      optionCheckColor: colors.primaryBase,
      optionTextColorActive: colors.primaryBase,
      optionColorActive: colors.primaryBg,
    },
    InternalSelection: {
      heightMedium: heights.base,
      fontSizeMedium: fonts.base,

      heightSmall: heights.sm,
      fontSizeSmall: fonts.base,

      fontSizeLarge: fonts.lg,
      heightLarge: heights.lg,

      borderRadius: borderRadius.lg,
      placeholderColor: colors.textPlaceholder,
      boxShadowFocus: 'none',

      // active
      borderActive: `1px solid ${colors.primaryBase}`,
      boxShadowActive: `0 0 0 2px ${colors.primaryBg}`,

      // default
      border: `1px solid ${colors.border}`,
      borderHover: `1px solid ${colors.border}`,
      borderFocus: `1px solid ${colors.border}`,
      color: colors.bgContainer,
      textColor: colors.text,
      caretColor: colors.text,
      paddingMultiple: paddings.xxs,

      // error
      borderError: `1px solid ${colors.errorBase}`,
      borderFocusError: `1px solid ${colors.errorBase}`,
      borderHoverError: `1px solid ${colors.errorBase}`,
      caretColorError: colors.text,
      boxShadowFocusError: 'none',

      // disabled
      colorDisabled: colors.bgLayout,
      textColorDisabled: colors.text,
    },
  },
};
