import type { AxiosInstance } from 'axios';
import type { LoadFooterResponse } from './types';

export const contentModule = (axios: AxiosInstance) => ({
  loadFooter() {
    return axios.get<LoadFooterResponse>('/v1/content/footer');
  },
});

export * as ContentModule from './types';
