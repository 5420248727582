import type { Widget } from './types';

class OmnideskWidget implements Widget {
  private src = `
    !function(e,o){!window.omni?window.omni=[]:'';window.omni.push(o);o.g_config={widget_id:"14499-mesc9f99"}; o.email_widget=o.email_widget||{};var w=o.email_widget;w.readyQueue=[];o.config=function(e){ this.g_config.user=e};w.ready=function(e){this.readyQueue.push(e)};var r=e.getElementsByTagName("script")[0];c=e.createElement("script");c.type="text/javascript",c.async=!0;c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";r.parentNode.insertBefore(c,r)}(document,[]);
  `;

  private script: HTMLScriptElement | null = null;

  create() {
    const script = document.createElement('script');
    script.text = this.src;

    document.head.appendChild(script);

    this.script = script;

    this.setWidgetConfig();
  }

  destroy() {
    // pass
  }

  private setWidgetConfig() {
    window.omni[0].config({
      yaCounterId: 93175160,
    });
  }
}

export default new OmnideskWidget();

declare global {
  interface Window {
    omni: any[];
  }
}
