import colors from '@modules/themeColors.module.scss';
import fonts from '@modules/fonts.module.scss';
import paddings from '@modules/paddings.module.scss';
import type { DataTableProps } from 'naive-ui';

export const lightDataTableTheme: NonNullable<DataTableProps['themeOverrides']> = {
  borderRadius: '0px',
  borderColor: colors.borderSecondary,
  thColor: colors.fillAlter,
  thFontWeight: fonts.weightNormal,
  thPaddingMedium: `${paddings.base}`,
  tdPaddingMedium: `${paddings.base}`,
  fontSizeMedium: fonts.base,
  lineHeight: '1.5',
};
