import { defineStore } from 'pinia';
import { StoreId } from '@/store';
import { UserRole } from '@/enums/roles';
import { api } from '@/services/api';
import type {
  UserUpdateData,
  UserState,
  UserAccountType,
  VerificationSendRequestData,
  VerificationVerifyRequestData,
  ChangePassword,
} from './types';

const useUserStore = defineStore(StoreId.USER, {
  state: (): UserState => ({
    user: undefined,
    userAccounts: null,
    phoneToEnter: null,
    emailToEnter: null,
    leftTime: null,
  }),

  getters: {
    roles(state) {
      if (!state.user) return [];

      return state.user.roles.map((role) => role.name);
    },

    isAdminRole(state) {
      if (!state.user?.role) return false;

      return [
        UserRole.ADMINISTRATOR,
        UserRole.SUPER_ADMINISTRATOR,
        UserRole.TER_ADMIN,
        UserRole.USER_MANAGER,
      ].includes(state.user.role);
    },

    isParentRole(state) {
      return state.user?.role === UserRole.PARENT;
    },

    isTeacherRole(state) {
      return state.user?.role === UserRole.TEACHER;
    },

    isStudentRole(state) {
      return state.user?.role === UserRole.STUDENT;
    },

    isLoggedAsAdmin(state) {
      return state.user?.isLoggedAs;
    },

    fullName(state) {
      if (!state.user) return '';

      const { firstName, lastName } = state.user;

      return [firstName, lastName]
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();
    },

    isTheLastAccount(state) {
      return state.userAccounts?.length === 1;
    },

    isTherePassword(state) {
      const found = state.userAccounts?.find((account) => {
        return account.type === 'phone' || account.type === 'email';
      });
      return !!found;
    },

    crmUuid(state) {
      if (!state.user) return;

      return state.user.crmUuid;
    },

    role(state) {
      if (!state.user) return;

      return state.user.role;
    },

    userGrade(state) {
      if (!state.user) return;

      return state.user.grade;
    },
  },

  actions: {
    async getUser() {
      const { data } = await api.profile.loadProfile();
      this.user = data;
    },

    async changeUserInfo(payload: UserUpdateData) {
      const {
        firstName,
        lastName,
        patronym,
        avatar,
        email,
        phone,
      } = payload;
      if (this.isParentRole) {
        await api.profile.updateProfile({
          firstName,
          lastName,
          patronym,
          avatar,
          email,
          phone,
        });
      } else {
        await api.profile.updateProfile({
          firstName,
          lastName,
          patronym,
          avatar,
        });
      }
      await this.getUser();
    },

    async changeAvatar(file: File) {
      const { data } = await api.profile.sendAvatarFile(file);

      if (!data.url) return;
      await this.changeUserInfo({ avatar: data.url });
    },

    async logoutAsAdmin() {
      await api.admin.logoutAs();

      const { data } = await api.profile.loadProfile();

      this.user = data;
    },

    async getUserAccounts() {
      const { data } = await api.profile.loadUserAccounts();
      this.userAccounts = data;
      this.setEnterPhoneAndEmail();
    },

    setEnterPhoneAndEmail() {
      this.phoneToEnter = null;
      this.emailToEnter = null;
      this.userAccounts?.forEach((account) => {
        if (account.type === 'phone') {
          this.phoneToEnter = account.identifier;
        }
        if (account.type === 'email') {
          this.emailToEnter = account.identifier;
        }
      });
    },

    async getSocialRedirectUrl(accountType: UserAccountType) {
      const { data } = await api.profile.getSocialRedirectUrl(accountType);
      return data.redirectUri;
    },

    async unlinkAccount(accountId: number) {
      await api.profile.unlinkAccount(accountId);
    },

    async sendVerification(payload: VerificationSendRequestData, accountType: UserAccountType | null) {
      const { data } = await api.profile.sendAccountVerification(payload, accountType);
      this.leftTime = data.noResendExpiresIn;
    },

    async verifyLinking(payload: VerificationVerifyRequestData, accountType: UserAccountType | null) {
      await api.profile.verifyAccountLinking(payload, accountType);
    },

    async setPasswordFirst(password: string | null) {
      await api.profile.setPassword(password);
    },

    async changePassword(payload: ChangePassword) {
      await api.profile.changePassword(payload);
    },
  },
});

export default useUserStore;
export * from './types';
