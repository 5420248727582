export const enum RouteName {
    HOME = 'home',
    ADMIN = 'admin',
    OAUTH_RESULT = 'oauth-result',

    CATALOG = 'catalog',
    CONTRACTS_AND_PAYMENTS = 'contracts-and-payments',
    CONTRACTS_AND_PAYMENTS_DETAIL = 'contracts-and-payments-detail',
    EVENTS = 'events',
    PROFILE = 'profile',
    PROGRESS = 'progress',
    SCHEDULE = 'schedule',
    TAX_DEDUCTION = 'tax-deduction',
    SCHEDULE_2035 = '2035',
    SCHEDULE_CHANGE = 'schedule-change',
    RKI = 'rki',
    GRADE_BOOK = 'grade-book',

    LOGOUT = 'logout',
    ERROR_403 = '403',
    ERROR_404 = '404',
    ERROR_500 = '500',
}
