import { defineStore } from 'pinia';
import { api } from '@/services/api';
import { StoreId } from '@/store';

import type { AuthState, OAuthResultRequest } from '@/types';

export const useAuthStore = defineStore(StoreId.AUTH, {
  state: (): AuthState => ({
    isAuthRedirect: false,
  }),

  actions: {
    async logout() {
      try {
        const { data } = await api.auth.getLogoutLink();
        return { response: data.url, error: null };
      } catch (error) {
        return { response: null, error };
      }
    },

    async oauthRedirect(fromUrl: string) {
      if (this.isAuthRedirect) return;

      // TODO: отсутствует логика изменения состояния при возникновении ошибки
      this.isAuthRedirect = true;

      const { data } = await api.auth.oauthRedirect(fromUrl);

      if (!data?.location) return false;

      window.location.replace(data.location);

      return true;
    },
    async oauthResult(payload: OAuthResultRequest) {
      const { data } = await api.auth.oauthResult(payload);

      if (!data?.location) return false;

      window.location.replace(data.location);

      return true;
    },
  },
});
