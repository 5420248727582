import type { GetUserRole, GetCrmUuid, YandexMetrikaManagerType, YandexCategory } from '../types';

const createDocumentsModule = (ymm: YandexMetrikaManagerType, getCrmUuid: GetCrmUuid, getUserRole: GetUserRole) => ({
  basic(category: YandexCategory, isSchedule: boolean) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const label = isSchedule ? 'payment_schedule' : 'no_contracts_choose_course';

    const payload = {
      Разработка: {
        PROFILE: {
          [`personal_account_${category}`]: {
            documents_basic: {
              [label]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },

  payment(category: YandexCategory, isNextPayment: boolean) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const label = isNextPayment ? 'pay_next_payment' : 'payment';

    const payload = {
      Разработка: {
        PROFILE: {
          [`personal_account_${category}`]: {
            documents_payment: {
              [label]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createDocumentsModule;
