import { defineStore } from 'pinia';
import { StoreId } from '@/store';
import { api } from '@/services/api';
import type { ConfigState } from './types';

const useConfigStore = defineStore(StoreId.CONFIG, {
  state: (): ConfigState => ({
    CONFIG: null,
  }),

  getters: {},

  actions: {
    async getConfig() {
      try {
        const { data } = await api.system.loadConfig();

        this.CONFIG = data;
      } catch (e) {
        console.error(e);
      }
    },
  },
});

export default useConfigStore;
export * from './types';
