import type { AxiosInstance } from 'axios';
import type { HeaderMenuResponse, Config } from './types';

export const systemModule = (axios: AxiosInstance) => ({
  loadConfig() {
    return axios.get<Config>('/v1/system/config');
  },

  loadMenu() {
    return axios.get<HeaderMenuResponse>('/v1/system/menu');
  },
});

export * from './enums';
export * as SystemModule from './types';
