import type { GetUserRole, GetCrmUuid, YandexMetrikaManagerType } from '../types';

const createScheduleChangeModule = (
  ymm: YandexMetrikaManagerType,
  getCrmUuid: GetCrmUuid,
  getUserRole: GetUserRole,
) => ({
  click(label: string) {
    const crmUuid = getCrmUuid();
    const userRole = getUserRole();

    if (!crmUuid) return;

    const payload = {
      Разработка: {
        PROFILE: {
          personal_account_click: {
            schedule_change: {
              [label]: {
                [crmUuid]: userRole,
              },
            },
          },
        },
      },
    };

    ymm.counters['37655510'].reachGoal('Событие ЛК', payload);
    ymm.counters['93175160'].reachGoal('Событие ЛК', payload);
  },
});

export default createScheduleChangeModule;
